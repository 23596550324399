<template>
  <div
    v-loading.fullscreen="loading"
    element-loading-spinner="el-icon-loading"
    :element-loading-text=loadingText
  >
  <!-- header -->
  <div>
    <div class="flex justify-center mt-8">
    <div class="flex items-center space-x-8 max-w-4xl p-4 md:w-4/5 text-left">
      <img
        class=" h-24"
        src="../../src/assets/Logo_orange_text-mainlogo.jpg"
        alt="logo"
      />
      <p class="muench text-2xl font-light pt-4">Registration & Payments</p>
    </div>
    </div>
  </div>
  <transition name="fade">
    <div class="flex justify-center ">
      <div
        class="border max-w-4xl p-4 rounded shadow-md w-7/8 md:w-4/5 text-left bg-white z-10 mb-4"
      >
        <p class="muench font-bold text-3xl pl-2 my-2">{{ title }}</p>
        <p class="font-semibold whitespace-pre-wrap leading-snug text-lg pl-3" v-if="subtitle != ''">{{ subtitle }}</p>
        <p class="font-semibold whitespace-pre-wrap leading-snug text-md pl-3 mt-2" v-if="this.confirmationText != ''">{{ this.confirmationText }}</p>
        <p class="font-semibold whitespace-pre-wrap leading-snug text-md pl-3 mt-2" v-if="this.footer != ''" v-html="this.footer"></p>
      <hr class="border border-dotted my-4" />
      <div v-if="!isError" class="pl-4">
        <table class="w-full">
          <tr v-if="!fields.genericPayment">
            <th>Workshop</th>
            <td>{{fields.title}}</td>
          </tr>
          <tr v-if="!fields.genericPayment">
            <th>Date</th>
            <td>{{fields.tripDate}}</td>
          </tr>
          <!-- <tr>
            <th>Confirmation Code</th>
            <td>{{confirmationCode}}</td>
          </tr> -->
          <tr>
            <th>Name</th>
            <td>{{fields.fname}} {{fields.lname}}</td>
          </tr>
          <tr>
            <th>Email</th>
            <td>{{fields.email}}</td>
          </tr>
          <tr v-if="(fields.type == 'register' || fields.type == 'pay') && !fields.isSoldOut || fields.isPreRegistration">
            <th>Payment Method</th>
            <td>{{paymentMethod === "Credit" ? paymentMethod + " Card" : paymentMethod}}</td>
          </tr>
          <tr v-if="fields.type == 'pay' || fields.waitlistPayment">
            <th>Amount Paid</th>
            <td>{{amountPayingDisplay}}</td>
          </tr>
          <tr v-if="fields.genericPayment">
            <th>Description</th>
            <td>{{fields.differentNote}}</td>
          </tr>
          <tr v-if="fields.type == 'register' && !fields.isSoldOut || fields.isPreRegistration">
            <th>Address</th>
            <td>
              {{fields.addr1}}<br>
              <div v-if='fields.addr2 != ""'>{{fields.addr2}}<br></div>
              {{fields.city}}, {{fields.state}} {{fields.zip}}<br>
              {{fields.country}}
            </td>
          </tr>
          <tr v-if="paymentMethod == 'pay'">
            <th>Address</th>
            <td>
              {{fields.billingAddr1}}<br>
              <div v-if='fields.billingAddr2 != ""'>{{fields.billingAddr2}}<br></div>
              {{fields.billingCity}}, {{fields.billingState}} {{fields.billingZip}}<br>
              {{fields.billingCountry}}
            </td>
          </tr>
          <tr v-if="paymentMethod == 'Credit' && fields.cabinDesc != null && fields.type =='register'">
            <th>Cabin</th>
            <td>
              {{fields.cabinDesc}}
            </td>
          </tr>
          <tr v-if="paymentMethod == 'Credit' && fields.type =='register'">
            <th>Selections</th>
            <td class="pr-2">
              <table class="w-full">
                <tr v-if="fields.isPayingDeposit" class="flex justify-between"><div>Deposit:</div><div>{{depositDisplay}}</div></tr>
                <tr v-if="fields.isPayingSecondPayment" class="flex justify-between"><div>Second Payment:</div><div>{{secondPaymentDisplay}}</div></tr>
                <tr v-if="fields.isPayingBalance" class="flex justify-between"><div>Balance:</div><div>{{balanceDisplay}}</div></tr>
                <tr v-if="fields.isPayingSingle" class="flex justify-between"><div>Single Occupancy Supplement:</div><div>{{singleDisplay}}</div></tr>
                <tr v-if="fields.isPayingOther" class="flex justify-between"><div>Custom Amount:</div><div>{{amountPayingDisplay}}</div></tr>
                <tr class="flex justify-between"><th class="border-t border-dotted border-gray-300">Total</th><div class="border-t border-dotted border-gray-300">{{amountPayingDisplay}}</div></tr>
              </table>
            </td>
          </tr>
          <tr v-if="this.nonRefundable && fields.type =='register' && (!fields.isSoldOut || fields.isPreRegistration)" >
            <th>Special Terms</th>
            <!-- <td class="whitespace-pre-line leading-tight max-w-xl pr-4">{{fields.specialTerms}}</td> -->
            <td>
              <ul class="list-disc">
                          <li>Your non-refundable deposit of {{this.toCurrency(this.fields.depositAmount)}} will hold your space in this workshop.</li>
                          <li>The non-refundable balance is due no later than {{this.balanceDueDate}}</li>
                          <li>All payments are non-refundable and this is why we recommend comprehensive travel insurance including trip cancelation.</li>
                          <li>
                            <p class="font-medium ">In order to register you must read and agree to the
                          <a
                            class="linky font-normal"
                            target="_blank"
                            href="http://muenchworkshops.com/terms-conditions"
                            >Terms and Conditions
                          </a>
                          <span class="text-red-500"> *</span>
                        </p>
                          </li>
                        </ul>
            </td>
          </tr>
          <tr v-if="this.saleWasApplied && ( ( this.onlineSaleDescription != null ) && ( this.onlineSaleDescription != undefined ) )">
            <th>Discounts</th>
            <td> {{ onlineSaleDescription }}</td>
          </tr>

        </table>
      </div>
      <div v-else class="pl-4">
        Try again or contact us.
      </div>
      </div>
    </div>
  </transition>
  </div>
</template>

<script>

export default {
  name: 'Confirmation',
  props: ["data"],
  data() {
    return {
      confirmationText: "",
      balanceDueDate: "",
      confirmationCode: "",
      fields: {},
      footer: "",
      header: "",
      subheader: "",
      waitlistHeader: "",
      waitlistSubheader: "",
      comingSoonHeader: "",
      comingSoonSubheader: "",
      isError: undefined,
      isNew: "",
      loading: false,
      loadingText: "Loading...",
      params: "",
      title: "",
      token: "",
      type: "",
      paymentMethod: "",
      subtitle: "",
      nonRefundable: "",
      onlineSalesDescription: "",
      saleWasApplied: ""
    };
  },
  mounted() {
    this.loadData();
  },
  computed:{
    amountPayingDisplay: function() {
      return this.toCurrency(this.fields.amount);
    },
    depositDisplay: function() {
      return this.toCurrency(this.fields.depositAmount);
    },
    secondPaymentDisplay: function() {
      return this.toCurrency(this.fields.secondPaymentAmount);
    },
    totalDisplay: function() {
      return this.toCurrency(this.fields.total);
    },
    otherDisplay: function() {
      return this.toCurrency(this.fields.differentDollar);
    },
    singleDisplay: function() {
      return this.toCurrency(this.fields.singleOccupancySupplement);
    },
    balanceDisplay: function() {
      return this.toCurrency(this.fields.balanceAmount);
    },
  },
  methods: {
    loadData(){
      try{
        this.loading = true

        //check for params
        if (this.data == undefined) {
          //missing data package
          this.title ="400"
          this.subtitle = "An error occurred."
          throw 400
       }else if (this.data.fields == undefined || this.data.result == undefined){
          //missing child data
          this.title ="422"
          this.subtitle = "An error occurred."
          throw 422
        }

      document.title = "Confirmation";
      var fields = this.data.fields
      var result = this.data.result

      //successful registration
      this.balanceDueDate = this.data.balanceDueDate
      this.nonRefundable = this.data.nonRefundable
      this.fields = fields
      this.confirmationCode = result.code
      this.confirmationText = result.confirmationText
      this.header = result.header
      this.footer = result.footer
      this.subheader = result.subheader
      this.waitlistHeader = result.waitlistHeader
      this.waitlistSubheader = result.waitlistSubheader
      this.comingSoonHeader = result.comingSoonHeader
      this.comingSoonSubHeader = result.comingSoonSubHeader
      // this.paymentMethod = fields.paymentMethod[0].toUpperCase() + fields.paymentMethod.substring(1)
      this.isNew = result.newUser
      this.type = fields.type
      if (fields.type =='pay') {
        this.paymentMethod = fields.paymentMethod[0].toUpperCase() + fields.paymentMethod.substring(1)
      }else if(fields.isSoldOut && !fields.isPreRegistration){
        this.title = this.waitlistHeader
        this.subtitle = this.waitlistSubheader

      }else if(fields.comingSoon){
        this.title = this.comingSoonHeader
        this.subtitle = this.comingSoonSubHeader

      }else if (fields.type == 'register'){
         this.title = this.header
        this.subtitle = this.subheader
        // this.subtitle = "An email with more information is on its way to " + fields.email +"."
        this.paymentMethod = fields.paymentMethod[0].toUpperCase() + fields.paymentMethod.substring(1)
      }
      //Sales Info
      this.onlineSalesDescription = this.data.onlineSalesDescription
      this.saleWasApplied = this.data.saleWasApplied
      
          
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

      }catch (e){
        this.isError = true
        console.log(e)
        let params = {
          error: e,
          workshopDetails: this.fields
        }
        sharedAPI.runScript('WebLog-SendErrorEmail', JSON.stringify(params))
      }finally{
        this.loading = false
      }
    },

    toCurrency(amt) {
      var formatCurrency = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      return formatCurrency.format(amt);
    },
  }
}
</script>
<style scoped>

tr td{
  border-top: dotted 1px #d1d1d1;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 8px;
  vertical-align: text-top;
}tr:first-child td {
  border-top: none;
}
tr td:last-child {
  border-bottom: none;
}
th {
  padding-right: 24px;
  vertical-align: text-top;
}
</style>